import React, { useCallback } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';

import { UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import SEO from '../components/seo';

export default function RequestDemoThankYouPage() {
  const handleSubmitSecondStep = useCallback(() => {
    window.open('https://calendly.com/d/cqv-w87-q8s/unitq-demo', '_blank');
  }, []);

  return (
    <UqLayoutV2>
      <UqAppBarV2>
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className="master-container request-demo-thank-you-page">
        <SEO title="Request unitQ Demo" />
        <Container fluid className="sign-up-form-container px-0 main">
          <Row noGutters className="no-header justify-content-center">
            <Col className="page-header-wrapper justify-content-center" />
          </Row>
          <div className="background-blue" />
          <Row noGutters>
            <Col>
              <Container className="text-center content-wrapper">
                <Row>
                  <Col className="layout-col">
                    <div className="mt-5">
                      <div className="sign-up-form-step-container">
                        <div className="sign-up-form-step-last-title">
                          Thank you for your interest in scheduling a unitQ demo
                        </div>
                        <p className="sign-up-form-step-last-dt">
                          A unitQ expert will reach out to you shortly. If you need any help in the meantime,
                          schedule a meeting using the button below.
                        </p>
                        <div className="sign-up-form-step-last-icon">
                          <img src={'/images/request-demo/Cloud_Complete.svg'} alt="" />
                        </div>
                        <Button
                          className="sign-up-form-schedule"
                          variant="primary"
                          type="button"
                          onClick={handleSubmitSecondStep}
                        >
                          Schedule a time
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
